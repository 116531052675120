<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" xl="4">
        <v-container class="pa-0 text-center">
          <h1>{{ $t('loggedOut') }}</h1>
          <p>{{ $t('loggedOutMsg') }}</p>
          <p>{{ $t('loggedOutHelperText') }}</p>
          <div class="d-flex justify-center">
            <v-btn color="primary" to="/login">{{ $t('login') }}</v-btn>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoggedOut',
};
</script>
